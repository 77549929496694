import request from '@/utils/request'
import qs from "qs"

// 获取分片ID
export function getUploadId(fileName) {
    return request({
        url: 'ossfile/getUploadId',
        method: 'get',
        params: fileName
    })
}
 // 上传 文件
export function shardUpload(data) {
    return request({
        url: 'ossfile/shardUpload',
        method: 'post',
        data: data
    })
}

// 合并请求
export function completeFile(uploadId) {
    return request({
        url: 'ossfile/completeFile',
        method: 'get',
        params: uploadId
    })
}