<template>
  <div class="menus" style="padding: 12px 100px">
    <el-form
      :model="froms"
      :rules="rules"
      ref="froms"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="类别" prop="pcate">
            <el-select
              v-model="froms.pcate"
              @change="fnEdit"
              placeholder="请选择一级分类"
              style="width: 100%"
            >
              <el-option
                v-for="item in yiji"
                :key="item.id"
                :value="item.id"
                :label="item.title"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="二级分类" prop="ccate">
            <el-select
              v-model="froms.ccate"
              placeholder="请选择二级分类"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="its in erji"
                :key="its.id"
                :value="its.id"
                :label="its.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标题" prop="title">
            <el-input v-model="froms.title" placeholder="请输入标题"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="关健词">
            <el-input
              v-model="froms.keywords"
              placeholder="请输入关键词"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="音频">
            <el-input
              v-model="froms.audio"
              placeholder="请输入音频地址"
              @input="froms.audio = froms.audio.replace(/\s+/g, '')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="视频">
            <el-input
              v-model="froms.video"
              @input="froms.video = froms.video.replace(/\s+/g, '')"
            >
              <el-button
                slot="append"
                size="small"
                type="primary"
                @click="btnUpload"
                placeholder="请输入视频地址"
              >
                点击上传</el-button
              >
              <!--                              <template slot="append" icon="el-icon-search">上传</template>-->
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="学习时长" prop="lengthtime">
            <el-input
              v-model="froms.lengthtime"
              placeholder="请输入学习时长(秒)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="排序" prop="displayorder">
            <el-input
              v-model="froms.displayorder"
              placeholder="请输入排序"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="是否显示" prop="status">
            <el-radio-group v-model="froms.status">
              <el-radio label="0">隐藏</el-radio>
              <el-radio label="1">显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="作者">
            <el-input
              v-model="froms.author"
              placeholder="请输入作者"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="说明">
            <el-input
              type="textarea"
              v-model="froms.description"
              placeholder="请输入说明"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="内容：">
            <HEllo
              v-model="detail"
              ref="ch"
              :value="value"
              :isClear="isClear"
              @change="change"
            ></HEllo>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button
          type="primary"
          style="margin-top: 15px"
          @click="submitForm('froms')"
          >保存</el-button
        >
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
    <!-- 添加或修改对话框  :before-remove="beforeRemove"-->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-upload
        class="upload-demo"
        action="/"
        accept=".mp4"
        :on-remove="handleRemove"
        :before-upload="beforeAvatarUpload"
        multiple
        :limit="1"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传mp4文件，且不超过500kb
        </div>
      </el-upload>
      <el-progress
        :text-inside="true"
        :stroke-width="16"
        :percentage="percentage"
        v-if="inside"
      ></el-progress>
    </el-dialog>
  </div>
</template>



<script>
import HEllo from "../../../components/HelloWorld.vue";
import request from "@/utils/request.js";
import axios from "axios";
import { getToken } from "@/utils/auth";
import { getUploadId, shardUpload, completeFile } from "@/api/upload";
export default {
  components: {
    HEllo,
  },
  data() {
    return {
      msg: "",
      value: "",
      htmls: "",
      froms: {},
      id: 0,
      yiji: [],
      erji: [],
      articleId: "", //跳转过来存放参数id
      open: false, // 弹窗开关
      title: "",
      fileList: [],
      uploadId: "",
      fileName: "",
      fileUrl: "",
      inside: false,
      percentage: undefined,
      isClear: false,
      detail: "",
      ruleForm: {},
      rules: {
        pcate: [{ required: true, message: "请选择", trigger: "blur" }],
        ccate: [{ required: true, message: "请选择", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        lengthtime: [
          { required: true, message: "请输入学习时长", trigger: "blur" },
          {
            pattern: /^[1-9][0-9]*$/,
            message: "请输入大于0的整数",
            trigger: "blur",
          },
        ],
        displayorder: [
          { required: true, message: "请输入排序", trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  created() {
    this.info();
  },

  methods: {
    //  富文本事件
    change(val) {
      this.value = val;
    },
    //  上传视频显示弹窗 先置空
    btnUpload() {
      this.title = "上传视频";
      this.fileList = [];
      this.open = true;
    },
    cancel() {
      this.inside = false;
      this.percentage = undefined;
      this.open = false;
    },

    handleRemove(file, fileList) {
      //  console.log(file, fileList);
    },
    async beforeAvatarUpload(file) {
      console.log(file);
      const isMp4 = file.type === "video/mp4";
      if (!isMp4) {
        this.$message.error("只能上传mp4文件!");
        return;
      }
      let chunkSize = 10 * 1024 * 1024; //10M
      let start = 0;
      let index = 1;
      let size = file.size;
      this.fileName = file.name;
      this.inside = true;
      if (size <= chunkSize) {
        let formData = new FormData();
        formData.append("file", file);
        // http://hbpxedu.boeyu.com/ossfile/upload
        // http://192.168.3.147:8087/ossfile/upload
        await axios
          .post("https://hbpxedu.boeyu.com/exam/ossfile/upload", formData, {
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "multipart/form-data;charset=utf-8",
            },
          })
          .then((res) => {
            this.fileUrl = res.data.data;
            this.froms.video = res.data.data;
            this.fileList.push({ name: this.fileName, url: this.fileUrl });
            this.fileList = [];
            this.open = false;
          });
        this.percentage = 100;
      } else {
        let fileName = this.fileName;
        await getUploadId({ fileName }).then((res) => {
          this.uploadId = res.data;
        });
        while (start < size) {
          let blob = null;
          // 判断切片是否最后一片 不足10M
          if (start + chunkSize > size) {
            blob = file.slice(start, size);
          } else {
            blob = file.slice(start, start + chunkSize);
          }
          start += chunkSize;
          let blobFile = new File([blob], fileName);
          let formData = new FormData();
          formData.append("uploadId", this.uploadId);
          formData.append("file", blobFile);
          formData.append("chunkId", index + "");
          //  console.log(formData);
          await axios.post(
            "https://hbpxedu.boeyu.com/exam/ossfile/shardUpload",
            formData,
            {
              headers: {
                Authorization: "Bearer " + getToken(),
                "Content-Type": "multipart/form-data;charset=utf-8",
              },
            }
          );
          index++;
          this.percentage = Math.ceil((start / size) * 100);
        }
        let uploadId = this.uploadId;
        await completeFile({ uploadId }).then((res) => {
          this.fileUrl = res.data.data;
          this.froms.video = res.data;
          //   console.log(res.data);
          this.fileList.push({ name: this.fileName, url: this.fileUrl });
          this.open = false;
        });
        this.percentage = 100;
      }
    },
    addUpload() {},
    info() {
      this.articleId = this.$route.query.id;
      //  console.log(this.articleId)
      if (this.articleId !== undefined) {
        this.particulars();
      } else {
        this.msg = "";
      }
      return request({
        url: "articleCategory/" + this.id,
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.yiji = res.data;
          //   console.log(this.yiji);
          // this.froms = res.data;
          // this.msg = this.froms.content;
        }
      });
    },
    //详情
    particulars() {
      return request({
        url: "article/selectArticleByArticleId",
        method: "get",
        params: {
          articleId: this.articleId,
        },
      }).then((res) => {
        if (res.status === 200) {
          //   console.log(res.data);
          this.froms = res.data;
          // this.value = this.froms.content;
          this.detail = this.froms.content;
          // console.log(this.froms.pcate)
          this.fnEdit(this.froms.pcate);
        }
      });
    },
    fnEdit(val) {
      return request({
        url: "articleCategory/" + val,
        method: "get",
      }).then((res) => {
        if (res.status === 200) {
          this.erji = res.data;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            (this.froms.video == undefined || this.froms.video == "") &&
            this.detail == ""
          ) {
            this.$message.error("请上传视频或输入内容");
            return;
          }
          let ch = this.$refs.ch;
          this.froms.content = this.value;
          if (this.articleId === undefined) {
            return request({
              url: "article/addArticle",
              method: "post",
              data: this.froms,
            }).then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.$router.push({ path: "/Home/substance" });
              }
            });
          } else {
            return request({
              url: "article/updateArticle",
              method: "put",
              data: this.froms,
            }).then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push({ path: "/Home/substance" });
              }
            });
          }
        } else {
          return false;
        }
      });
      return;
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  width: auto;
  height: auto;
}
.el-pagination {
  text-align: right;
}
::v-deep .el-form-item__content {
  text-align: left !important;
}
</style>
 
